<template>
  <form>
    <ValidationProvider name="inventoryLocations" v-slot="{ errors }">
      <div>
        <h4 class="title">Inventory Location - Type of Location</h4>
        <label class="form_subtitle">
          Please provide addresses for all locations where inventory is stored
          in the US. (i.e. warehouse, manufacturing plant, third-party
          fulfillment center, retail stores)
        </label>
        <div class="inventory-details">
          <template v-for="(inventory, index) in Payload.inventoryLocations">
            <div class="inventory_list" :key="inventory._id || index">
              <div class="inventory_cnt">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Type</label>
                      <ValidationProvider
                        name="Market Place"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <BaseRadioButtonGroup
                          :options="inventory_location_type"
                          v-model="inventory.typeDetails"
                          @input="updateinventory(inventory)"
                          :selectedValue="inventory.typeId || ''"
                          :name="'inventory_location_type_' + index"
                        />
                        <input
                          v-model="inventory.otherInventoryLocation"
                          type="text"
                          placeholder="Enter other Inventory Location"
                          class="form-control"
                          v-if="
                            inventory.typeDetails &&
                            inventory.typeDetails._id == 7
                          "
                        />
                        <span class="v-messages error-msg" v-if="errors[0]">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div
                  class="delete_btn"
                  v-if="Payload.inventoryLocations.length > 1"
                  @click="removeInventory(index)"
                >
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </div>

                <addressComponent
                  :autoSave="autoSave"
                  v-if="ClientDataloaded"
                  :address="inventory.address"
                  :addressType="'inventory' + index"
                  :isRequired="
                    inventory.typeDetails &&
                    inventory.typeDetails.name !== 'N/A'
                  "
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div @click="addInventory()" class="primary_btn add_more">
        Add Inventory
      </div>
      <span class="v-messages error-msg" v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </ValidationProvider>
  </form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import addressComponent from '@/components/address.vue';
import BaseRadioButtonGroup from '@/components/BaseRadioButtonGroup.vue';
import _ from 'lodash';

export default {
  data() {
    return {
      openDate: new Date().setFullYear(new Date().getFullYear()),
    };
  },
  components: {
    ValidationProvider,
    addressComponent,
    BaseRadioButtonGroup,
  },
  props: {
    Payload: {
      type: Object,
      required: true,
    },
    ClientDataloaded: {
      type: Boolean,
      required: true,
    },
    autoSave: {
      type: Function,
      required: true,
    },
    inventory_location_type: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    updateinventory(item) {
      item.typeId = null;
      if (_.has(item, 'typeDetails') && _.has(item['typeDetails'], '_id')) {
        item.typeId = item.typeDetails['_id'];
      }
    },
    removeInventory(index) {
      this.Payload.inventoryLocations.splice(index, 1);
    },
    addInventory() {
      let sp = {
        typeId: null,
        typeDetails: '',
        otherInventoryLocation: '',
        address: {
          addr1: '',
          addr2: '',
          stateId: null,
          cityId: null,
          zip: '',
          cntryId: null,
        },
      };
      this.Payload.inventoryLocations.push(sp);
    },
  },
};
</script>
