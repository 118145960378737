<template>
  <form>
    <div>
      <h4 class="title">Accounting Method</h4>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="form_label"
              ><b>Accounting Period Year End Date</b></label
            >
            <p style="margin-bottom: 15px; color: #19213e">
              For most companies, there accounting period year end date is
              12/31. If unsure, please refer to your previous year's tax return.
            </p>

            <ValidationProvider
              name="TaxPeriodbeginning"
              rules="required"
              v-slot="{ errors }"
            >
              <multiselect
                v-model="Payload.taxBegginingPeriodDetails"
                :value="'id'"
                :options="allMonths"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="false"
                :multiple="false"
                label="name"
                placeholder="Select Tax period beginning"
                track-by="name"
                @input="updatetaxBegginingPeriodDetails"
              >
              </multiselect>
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="form_label">Description of Items Sold</label>
            <ValidationProvider
              name="Description of Items Sold"
              rules="required|max:100"
              v-slot="{ errors }"
            >
              <input
                type="text"
                @blur="autoSave"
                name="itemDescription"
                v-model="Payload.itemDescription"
                class="form-control"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="form_label"
              >NAICS Code
              <a href="https://www.naics.com/search/" target="_blank">
                <img
                  style="width: 14px; margin-top: -4px"
                  src="@/assets/images/question_mark.svg"
                />
              </a>
            </label>

            <ValidationProvider
              name="NAICS Code"
              rules="required|min:5|max:25"
              v-slot="{ errors }"
            >
              <input
                type="number"
                @blur="autoSave"
                name="NAICSCode"
                v-model="Payload.NAICSCode"
                class="form-control"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="form_label">Accounting Method</label>
            <ValidationProvider
              :name="'Accounting Method'"
              rules="required"
              v-slot="{ errors }"
            >
              <multiselect
                v-model="Payload.accMethodDetails"
                :options="acc_method"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :allow-empty="false"
                @input="selectedAccount"
                label="name"
                value="value1"
                placeholder="Select Accounting Method"
                track-by="name"
                :name="'Accounting Method'"
              >
              </multiselect>

              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0] ? 'Accounting Method is required' : ''
              }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="form_label">Input Your Industry</label>
            <ValidationProvider
              name="Industry"
              rules="required|max:100"
              v-slot="{ errors }"
            >
              <input
                type="text"
                @blur="autoSave"
                name="industryIds"
                v-model="Payload.industryIds"
                placeholder="i.e. Fitness Equipment, Food, Beverage, Appareal, Pet Supplies, etc."
                class="form-control"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import Multiselect from 'vue-multiselect-inv';
import _ from 'lodash';

export default {
  data() {
    return {
      openDate: new Date().setFullYear(new Date().getFullYear()),
    };
  },
  components: {
    ValidationProvider,
    Multiselect,
  },
  props: {
    Payload: {
      type: Object,
      required: true,
    },
    autoSave: {
      type: Function,
      required: true,
    },

    allMonths: {
      type: Array,
      required: true,
      default: () => [],
    },
    acc_method: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    updatetaxBegginingPeriodDetails(item) {
      if (_.has(item, 'id')) {
        this.Payload.taxBegginingPeriod = item.id;
      } else {
        this.Payload.taxBegginingPeriod = '';
      }
    },
    selectedAccount(method) {
      _.forEach(this.acc_method, (obj, index) => {
        this.acc_method[index]['selected'] = false;
        if (obj['_id'] == method['_id']) {
          this.Payload.accMethodId = method['_id'];
          this.acc_method[index]['selected'] = true;
        }
      });

      this.Payload.accMethodDetails = method;
      this.Payload.accMethodId = method._id;
    },
  },
};
</script>
