<template>
  <div>
    <template v-if="loaded">
      <ValidationObserver ref="address">
        <!-- <h4 class="title">Address</h4>-->

        <div class="row">
          <!--
    <div class="col">
        <div class="form-group">
            <label class="form_label">Home Address</label>

            <ValidationProvider name="Address Line" rules="required" v-slot="{ errors }">
                    <input :name="addressType+' Address Line'"  v-model="address.addr1"  type="text" class="form-control" :disabled="isDisabled">
                    <span class="v-messages error-msg"  v-if="errors[0]" >{{ errors[0]?'Home Address is required':'' }}</span>
            </ValidationProvider>
        </div>
    </div>
    -->
          <div class="col">
            <div class="form-group">
              <label class="form_label">Street Address</label>
              <ValidationProvider
                name="Address Line2"
                :rules="isRequired ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input
                  :name="addressType + ' Address Line2'"
                  v-model="address.addr2"
                  type="text"
                  @blur="autoSave"
                  class="form-control"
                  :disabled="isDisabled"
                />
                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0] ? 'Street Address is required' : ''
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form_label">City</label>
              <ValidationProvider
                name="City"
                :rules="isRequired && cityList.length > 0 ? 'required' : ''"
                v-slot="{ errors }"
              >
                <!--<select  :name="addressType+' City'" v-model="address.cityId"  id="mycity" class="selectpicker"  data-live-search="false">
                    <template v-for="(city ,i) in cityList">
                    <option :value='city._id' :key="i" >{{city.name}}</option>
                    </template>

                </select>-->

                <multiselect
                  v-model="cityObject"
                  :value="'_id'"
                  :options="cityList"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="true"
                  @input="cityChanged"
                  label="name"
                  placeholder="Select City"
                  track-by="name"
                  :id="addressType + ' City'"
                  :disabled="isDisabled || cityList.length <= 0"
                >
                </multiselect>
                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label class="form_label">State</label>

              <ValidationProvider
                name="State"
                :rules="isRequired && stateList.length > 0 ? 'required' : ''"
                v-slot="{ errors }"
              >
                <!-- <select :name="addressType+' State'" v-model="address.stateId"  id="states" class="selectpicker"  data-live-search="false">
                     <template v-for="(sta ,indx) in stateList"  >
                         <option :key="indx" :value="sta._id" >{{sta.name}}</option>
                    </template>
                </select> -->

                <multiselect
                  v-model="stateObject"
                  :value="'_id'"
                  :options="stateList"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="true"
                  @input="stateChanged"
                  label="name"
                  placeholder="Select State"
                  track-by="name"
                  :id="addressType + ' State'"
                  :disabled="isDisabled || stateList.length <= 0"
                >
                </multiselect>
                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form_label">Zip Code</label>
              <!--numeric|required|min:5|max:5-->
              <ValidationProvider
                name="Zip Code"
                :rules="isRequired ? 'required|min:5|max:6|zipcodev' : ''"
                v-slot="{ errors }"
              >
                <input
                  oninput="this.value = this.value.replace(/[^0-9 .]/g, '').replace(/(\..*)\./g, '$1'); this.value = this.value.replace(/ /g,'');"
                  @blur="autoSave"
                  :name="addressType + ' Zip Code'"
                  v-model="address.zip"
                  class="form-control"
                  :disabled="isDisabled"
                />
                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="form_label">Country </label>

              <ValidationProvider
                name="Country"
                :rules="isRequired ? 'required' : ''"
                v-slot="{ errors }"
              >
                <multiselect
                  v-model="countryObject"
                  :value="'_id'"
                  :options="countryList"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="true"
                  @input="countryChanged"
                  label="name"
                  :taggable="false"
                  placeholder="Select Country"
                  :disabled="isDisabled"
                  track-by="name"
                  :id="addressType + ' Country'"
                >
                </multiselect>
                <!-- <select :name="addressType+' Country'" v-model="address.cntryId" id="country" class="selectpicker"  data-live-search="false">
                    <template v-for="(country ,ind) in countryList"  >
                         <option :key="ind" :value="country._id" >{{country.name}}</option>
                    </template>

                </select> -->
                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Multiselect from 'vue-multiselect-inv';
import _ from 'lodash';

export default {
  name: 'addressComponent',
  components: {
    ValidationObserver,
    ValidationProvider,
    Multiselect,
  },
  inject: ['parentValidator'],
  created() {
    this.$validator = this.parentValidator;
  },
  props: {
    address: {
      addr1: '',
      addr2: '',
      stateId: null,
      cityId: null,
      zip: '',
      cntryId: 231,
      countryDetails: null,
      stateDetails: null,
      locationDetails: null,
    },
    addressType: {
      type: String,
      default: '',
    },
    isDisabled: {
      required: false,
      type: Boolean,
    },
    autoSave: function () {},
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userdata: null,
      countryObject: {
        _id: 231,
        sName: 'US',
        name: 'United States',
        phoneCode: 1,
        curSymbol: '$',
        curCode: 'USD',
        zipLen: 5,
        phoneMin: 9,
        phoneMax: 10,
      },
      stateObject: null,
      cityObject: null,

      loaded: false,
      countryList: [],
      stateList: [],
      cityList: [],
      selected: null,
      options: ['list', 'of', 'options'],
    };
  },
  mounted() {
    this.getCountryList();

    //alert(this.address.countryDetails);
    if (
      this.address.countryDetails != null &&
      _.has(this.address.countryDetails, '_id')
    ) {
      this.address.cntryId = this.address.countryDetails._id;
      this.countryChanged(this.address.countryDetails, false);
    } else if (this.countryObject != null && _.has(this.countryObject, '_id')) {
      this.address.cntryId = this.countryObject._id;
      this.address.countryDetails = this.countryObject;
      this.countryChanged(this.address.countryDetails, false);
    }

    this.loaded = true;
  },
  methods: {
    countryChanged(item) {
      this.address.cntryId = item._id;

      this.stateList = [];
      // this.stateObject  = null;
      // this.address.stateId = null;

      this.cityList = [];
      //  this.cityObject =null;
      // this.address.cityId = null;
      this.getStateList();
      this.autoSave();
    },
    stateChanged(item) {
      this.address.stateId = item._id;
      this.cityList = [];
      // this.cityObject =null;
      //this.address.cityId = null;
      this.getCityList();
      this.autoSave();
    },
    cityChanged(item) {
      this.address.cityId = item._id;
      //this.getCityList();
      this.autoSave();
    },
    getCountryList() {
      let payLoad = {
        matcher: {
          searchString: '',
          //"cntryId": 231,
          //"stateId": 665,
          cntryIds: [231], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: 'country', // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch('getMaterData', payLoad)
        .then((res) => {
          this.countryList = res.list;

          if (this.address.cntryId && this.countryList.length > 0) {
            this.countryObject = _.find(this.countryList, {
              _id: this.address.cntryId,
            });
            this.getStateList();
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },
    getStateList() {
      let payLoad = {
        matcher: {
          searchString: '',
          //"cntryId": 101,
          //"stateId": 665,
          cntryIds: [231], //[this.address.cntryId], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: 'state', // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch('getMaterData', payLoad)
        .then((res) => {
          this.stateList = res.list;

          if (
            this.address.stateDetails != null &&
            _.has(this.address.stateDetails, '_id')
          ) {
            this.stateObject = this.address.stateDetails;
            this.address.stateId = this.address.stateDetails._id;
            this.getCityList();
          } else if (this.address.stateId) {
            this.stateObject = _.find(this.stateList, {
              _id: this.address.stateId,
            });
            if (this.stateObject) this.getCityList();
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },

    getCityList() {
      let payLoad = {
        matcher: {
          searchString: '',
          //"cntryId": 101,
          //"stateId": 665,
          cntryIds: [231], //[this.address.cntryId], // Requied for "state"
          stateIds: [this.address.stateId], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: 'city', // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch('getMaterData', payLoad)
        .then((res) => {
          this.cityList = res.list;

          if (
            this.address.locationDetails != null &&
            _.has(this.address.locationDetails, '_id')
          ) {
            this.cityObject = this.address.locationDetails;
          } else if (this.address.cityId) {
            this.cityObject = _.find(this.cityList, {
              _id: this.address.cityId,
            });
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },
  },
  computed: {},
  beforeCreate() {
    //console.log(this.showLoading);
  },
};
</script>
