<template>
  <div>
    <div class="form_title">
      <h3>General Information</h3>
      <p>
        *Please ensure the information on this form is correct, including
        spelling, this is the exact information we give to the states
      </p>
      <div class="form_section">
        <form>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="form_label">Legal Name Of Business</label>
                <ValidationProvider
                  name="Business Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="Payload.busName"
                    type="text"
                    @blur="autoSave"
                    class="form-control"
                  />
                  <span class="v-messages error-msg" v-if="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="form_label">Trade Name (DBA)</label>
                <ValidationProvider
                  name="Trade Name"
                  rules="max:50"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="Payload.tradeName"
                    type="text"
                    @blur="autoSave"
                    class="form-control"
                  />
                  <span class="v-messages error-msg" v-if="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="form_section white_bg">
      <form @submit.prevent="submitForm">
        <div>
          <h4 class="title">Legal Business Address</h4>
          <addressComponent
            v-bind:autoSave="autoSave"
            v-if="ClientDataloaded"
            v-bind:address="Payload.busAddress"
            v-bind:addressType="'add'"
          />
        </div>
        <span class="divider"></span>
        <div>
          <h4 class="title">Mailing Address</h4>
          <div class="row">
            <div class="col d-flex">
              <div class="form-group">
                <label class="radio_label"
                  >Use same as above
                  <input
                    type="radio"
                    :value="true"
                    name="mailingAddress"
                    v-model="mailingAddressAsSame"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <label class="radio_label"
                  >Enter new address
                  <input
                    type="radio"
                    :value="false"
                    name="mailingAddress"
                    v-model="mailingAddressAsSame"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <!-- <div class="form-group">
										<label class="radio_label">Use TaxHero as mailing address
											<input
												type="radio"
												:value="null"
												name="mailingAddress"
												v-model="mailingAddressAsSame"
											/>
											<span class="checkmark"></span>
										</label>
									</div> -->
            </div>
          </div>
        </div>

        <div v-if="mailingAddressAsSame === true">
          <addressComponent
            v-bind:autoSave="autoSave"
            v-if="ClientDataloaded"
            v-bind:address="Payload.mailingAddress"
            v-bind:addressType="'mailingSameAbove'"
            :isDisabled="true"
            :key="'mailingSameAboveAddressComponent'"
          />
        </div>
        <div v-if="mailingAddressAsSame === false">
          <addressComponent
            v-bind:autoSave="autoSave"
            v-if="ClientDataloaded"
            v-bind:address="Payload.mailingAddress"
            v-bind:addressType="'mailing'"
            :key="'mailingAddressComponent'"
          />
        </div>
        <div v-if="mailingAddressAsSame === null">
          <addressComponent
            v-bind:autoSave="autoSave"
            v-if="ClientDataloaded"
            v-bind:address="Payload.mailingAddress"
            v-bind:addressType="'mailingTaxhero'"
            :isDisabled="mailingAddressAsSame === null"
            :key="'mailingTaxheroAddressComponent'"
          />
        </div>
        <!-- <span class="divider"></span>
						<div>
							<h4 class="title">Location Of Tax Records</h4>
							<addressComponent
								v-bind:autoSave="autoSave"
								v-if="ClientDataloaded"
								v-bind:address="Payload.taxRecordLocation"
								v-bind:addressType="'tax_address'"
							/>
						</div> -->
      </form>
    </div>
    <div class="row form_section">
      <div class="col">
        <label class="form_subtitle"
          >Would you like TaxHero Inc. to be the mailing address for all sales
          tax related letters, notices and permits?</label
        >
        <ul class="d-flex--">
          <li>
            <div class="form-group">
              <label class="radio_label"
                >YES
                <input
                  type="radio"
                  :value="true"
                  name="mailingAddressOption"
                  v-model="Payload.isTaxheroMailingAddress"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </li>
          <li>
            <div class="form-group">
              <label class="radio_label"
                >NO
                <input
                  type="radio"
                  :value="false"
                  name="mailingAddressOption"
                  v-model="Payload.isTaxheroMailingAddress"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import addressComponent from '@/components/address.vue';
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    addressComponent,
    ValidationProvider,
  },
  props: {
    blocks: {
      type: Object,
      required: true,
    },
    Payload: {
      type: Object,
      required: true,
    },
    autoSave: {
      type: Function,
      required: true,
    },
    ClientDataloaded: {
      type: Boolean,
      required: true,
    },
    mailingAddressAsSame: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    'Payload.busAddress': {
      handler(newAddress) {
        if (this.mailingAddressAsSame === true) {
          this.Payload.mailingAddress = { ...newAddress };
        }
      },
      deep: true,
    },
    mailingAddressAsSame(newValue) {
      if (newValue === true) {
        this.Payload.mailingAddress = { ...this.Payload.busAddress };
      } else if (newValue === false) {
        this.Payload.mailingAddress = {};
      }
    },
  },
};
</script>
