var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":'supplier'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('form',[_c('div',[_c('h4',{staticClass:"title"},[_vm._v(" Supplier Info ")]),_c('label',{staticClass:"form_subtitle"},[_vm._v(" If you are already registered in the state of California, you can skip this question. ")]),_c('div',{staticClass:"supplier-details"},[_vm._l((_vm.Payload.supplier),function(supplier,index){return [_c('div',{key:index,staticClass:"suppliers_list"},[_c('div',{staticClass:"suppliers_cnt"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"name":"Supplier Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"value":'_id',"options":_vm.supplier_type,"searchable":true,"close-on-select":true,"allow-empty":true,"multiple":false,"label":"name","placeholder":"Select Supplier Type","track-by":"name","hide-selected":false},on:{"input":function($event){return _vm.updatesupplier(supplier)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__selectcustom"},[_vm._v(_vm._s(values.length)+" Supplier Type (s) selected")]):_vm._e(),(values.length && isOpen)?_c('span',{staticClass:"multiselect__selectcustom"}):_vm._e()]}}],null,true),model:{value:(supplier.typeDetails),callback:function ($$v) {_vm.$set(supplier, "typeDetails", $$v)},expression:"supplier.typeDetails"}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])]),(_vm.Payload.supplier.length > 1)?_c('div',{staticClass:"delete_btn",on:{"click":function($event){return _vm.removeSuplier(index)}}},[_c('b-icon',{attrs:{"icon":"trash","aria-hidden":"true"}})],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Name a supplier/vendor in the US ")]),_c('ValidationProvider',{attrs:{"name":'Supplier Name' + index,"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(supplier.name),expression:"supplier.name"}],staticClass:"form-control",attrs:{"type":"text","name":"MarketPlace"},domProps:{"value":(supplier.name)},on:{"blur":_vm.autoSave,"input":function($event){if($event.target.composing){ return; }_vm.$set(supplier, "name", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(supplier.name.length > 50 ? 'Supplier Name allow only 50 characters' : 'Supplier Name is required')+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Phone Number")]),_c('ValidationProvider',{attrs:{"name":"Supplier Phone Number","rules":"phonenumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":"US","no-example":true,"only-countries":['US'],"no-country-selector":true,"required":true},on:{"update":_vm.updatePhoneNumber},model:{value:(supplier.phone),callback:function ($$v) {_vm.$set(supplier, "phone", $$v)},expression:"supplier.phone"}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Email Address")]),_c('ValidationProvider',{attrs:{"name":'Supplier Email',"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(supplier.email),expression:"supplier.email"}],staticClass:"form-control",attrs:{"type":"text","name":"MarketPlace"},domProps:{"value":(supplier.email)},on:{"blur":_vm.autoSave,"input":function($event){if($event.target.composing){ return; }_vm.$set(supplier, "email", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])]),(_vm.ClientDataloaded && supplier.typeDetails)?_c('addressComponent',{attrs:{"autoSave":_vm.autoSave,"address":supplier.address,"addressType":'supplier'}}):_vm._e()],1)])]})],2)]),_c('div',{staticClass:"primary_btn add_more",on:{"click":function($event){return _vm.addSuplier()}}},[_vm._v("Add Supplier")])]),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }