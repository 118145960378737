<template>
  <div>
    <form>
      <div class="row">
        <!-- <div class="col">
                        <div class="form-group">
                          <label class="form_label"
                            >Business Email/Shared Mailbox

                            <span> -->
        <!-- <a
                                :id="'remarks1' + index"
                                class="download_form"
                                @click="download_file(item['documents'][0])"
                                ><b-icon
                                  icon="download"
                                  aria-hidden="true"
                                ></b-icon
                                >Download</a
                              >

                              <b-tooltip :target="'remarks1' + index">
                                <p v-if="UpdateAdjustMentAmount(item) != 0">
                                  Difference Amount:
                                  {{ UpdateAdjustMentAmount(item) }}
                                </p>
                                <p
                                  v-if="checkProperty(item, 'comment') != '--'"
                                >
                                  {{ checkProperty(item, "comment") }}
                                </p>
                              </b-tooltip> -->
        <!-- <img
                                :id="'busEmailMailbox'"
                                style="width: 14px; margin-top: -4px"
                                src="@/assets/images/info_icon.svg"
                              />

                              <b-tooltip
                                :target="'busEmailMailbox'"
                                data-html="true"
                              >
                                <p style="width: 350px">
                                  Please create a new dedicated email address
                                  for sales tax using your domain
                                  (salestax@yourcompanydomainnamehere.com).
                                </p>
                              </b-tooltip>
                            </span>
                          </label>
                          <ValidationProvider
                            name="Business email"
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <input
                              v-model="Payload.busEmail"
                              type="text"
                              @blur="autoSave"
                              class="form-control"
                            />
                            <span
                              class="v-messages error-msg"
                              v-if="errors[0]"
                              >{{ errors[0] }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div> -->
        <div class="col">
          <div class="form-group date_desable">
            <label class="form_label">Business Website</label>
            <input
              v-model="Payload.busContact.website"
              type="text"
              @blur="autoSave"
              class="form-control"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group date_desable">
            <label class="form_label">Business Phone Number</label>
            <ValidationProvider
              name="Business Phone Number"
              rules="required|phonenumber"
              v-slot="{ errors }"
            >
              <VuePhoneNumberInput
                default-country-code="US"
                :no-example="true"
                :only-countries="['US']"
                :no-country-selector="true"
                @update="updatePhoneNumber1"
                :required="true"
                v-model="Payload.busPhone"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="form_label">Business Representative Name</label>

            <ValidationProvider
              name="Business Representative Name"
              rules="required|max:50"
              v-slot="{ errors }"
            >
              <input
                v-model="Payload.busContact.name"
                type="text"
                @blur="autoSave"
                class="form-control"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="col">
          <div class="form-group date_desable">
            <label class="form_label">Representative Phone Number</label>
            <ValidationProvider
              name="Representative Phone Number"
              rules="required|phonenumber"
              v-slot="{ errors }"
            >
              <VuePhoneNumberInput
                default-country-code="US"
                :no-example="true"
                :only-countries="['US']"
                :no-country-selector="true"
                @update="updatePhoneNumber"
                :required="true"
                v-model="Payload.busContact.phone"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group date_desable">
            <label class="form_label">Business Representative Email</label>
            <ValidationProvider
              name="Business Representative email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <input
                v-model="Payload.busContact.email"
                type="text"
                @blur="autoSave"
                class="form-control"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label class="form_label">Date of Incorporation/Formation</label>
            <div
              id="duedate"
              class="input-group date"
              data-date-format="dd-mm-yyyy"
            >
              <ValidationProvider
                name="Date of Incorporation/Formation"
                rules="required"
                v-slot="{ errors }"
              >
                <datepicker
                  :open-date="new Date(openDate)"
                  :disabled-dates="{
                    to: new Date(2000, 0, 1), // Disable all dates up to specific date
                    from: new Date(), // Disable all dates after specific date
                  }"
                  :required="true"
                  format="yyyy-MM-dd"
                  v-model="Payload.busFormatedOn"
                  name="uniquename"
                  :typeable="true"
                ></datepicker>

                <span class="input-group-addon"
                  ><i class="glyphicon glyphicon-calendar"></i
                ></span>
                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <!--
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="form_label">Federal Employer ID</label>

                                               <ValidationProvider name="Federal Employer ID" rules="required|min:4|max:15" v-slot="{ errors }">
                                                    <input v-model="Payload.fedaralEmpId"  type="text"
                                                    @blur="autoSave" class="form-control">
                                                    <span  class="v-messages error-msg" v-if="errors[0]">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            </div>
                                        </div> -->
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="form_label">EIN</label>
            <ValidationProvider
              name="EIN"
              rules="min:10|max:11"
              v-slot="{ errors }"
            >
              <input
                v-model="Payload.EIN"
                type="text"
                @blur="autoSave"
                @input="formatEinInput"
                class="form-control"
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label class="form_label">Incorporation State</label>

            <ValidationProvider
              name="Incorporation State"
              rules="required"
              v-slot="{ errors }"
            >
              <multiselect
                v-model="Payload.incorporatedStateDetails"
                :value="'_id'"
                :options="usstateList"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="true"
                @input="updateIncorporatedState"
                label="name"
                placeholder="Select Incorporation State"
                track-by="name"
              >
              </multiselect>
              <!-- <select :name="addressType+' Country'" v-model="address.cntryId" id="country" class="selectpicker"  data-live-search="false">
                                                        <template v-for="(country ,ind) in countryList"  >
                                                            <option :key="ind" :value="country._id" >{{country.name}}</option>
                                                        </template>

                                                    </select> -->
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ValidationProvider
            name="Business structure"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <label class="form_label">Entity Type</label>
              <BaseRadioButtonGroup
                :options="bus_structure"
                v-model="Payload.busStructureDetails"
                @input="updateBusStructure"
                :selectedValue="
                  Payload.busStructureDetails
                    ? Payload.busStructureDetails._id
                    : ''
                "
                name="bus_structure"
              />
              <input
                v-model="Payload.busStructureDetailsOther"
                type="text"
                @blur="autoSave"
                class="form-control"
                v-if="
                  Payload.busStructureDetails &&
                  Payload.busStructureDetails._id == 6
                "
              />
              <span class="v-messages error-msg" v-if="errors[0]">{{
                errors[0]
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <label class="form_label">Secretary of State Number</label>
      <p style="margin-bottom: 15px">
        Input all numbers and states by clicking “More” – Ex. CA-123456789
      </p>
      <template v-for="(item, indx) in Payload.secretaryStateNumbers">
        <div class="row" :key="indx">
          <div class="col">
            <div class="form-group">
              <ValidationProvider
                name="Secretary State Number"
                v-slot="{ errors }"
              >
                <input
                  v-model="item.number"
                  type="text"
                  @blur="autoSave"
                  class="form-control"
                />
                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
            <span
              v-if="Payload.secretaryStateNumbers.length > 1"
              class="delete_btn delete_btn2"
              style="right: -35px; top: 4px"
              @click="removesecretaryStateNumber(indx)"
              ><b-icon icon="trash" aria-hidden="true"></b-icon
            ></span>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <span
              class="primary_btn add_more d-inline-flex w-auto"
              @click="addsecretaryStateNumber()"
              >+More</span
            >
          </div>
        </div>
      </div>

      <div class="row" v-if="checkCaliforniaState">
        <div class="col">
          <div class="form-group">
            <label class="form_label">Articles of Organization</label>
            <div class="upload_sec">
              <uploadFile
                @onUploadComplete="onUploadComplete"
                :allowMultiple="true"
                :acceptedFiles="'*'"
                :indexValue="0"
                :takeIndex="false"
                :payLoadKey="'articlesOfOrgAndDriverLiecenses'"
                :payLoadDocumentsKey="''"
              />
            </div>
            <ValidationProvider
              name="Articles of Organization"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                type="hidden"
                class="form-control"
                v-model="articlesInput"
              />
              <span class="v-messages error-msg" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <ul class="uploaded-list">
              <template
                v-for="(other, indx) in Payload.articlesOfOrgAndDriverLiecenses"
              >
                <li :key="indx">
                  {{ other.name }}
                  <span
                    @click="
                      removeDoc(indx, Payload.articlesOfOrgAndDriverLiecenses)
                    "
                    >X</span
                  >
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </form>
    <template v-if="checkProperty(Payload, 'busStructureId') == 3">
      <div class="form_section">
        <form>
          <h6>
            What have you elected to be treated as for Federal Income Tax
            Purposes?
          </h6>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <!-- <input type="text"
                           @blur="autoSave" class="form-control">-->
                <ValidationProvider
                  name="Entity Type"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <multiselect
                    v-model="Payload.llcTypeDetails"
                    :options="llc_type"
                    :searchable="true"
                    :close-on-select="true"
                    :allow-empty="true"
                    @input="updatellcTypes"
                    label="name"
                    value="value1"
                    placeholder="Select the Entity Type"
                    track-by="name"
                    :multiple="true"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} selected</span
                      >
                      <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                      ></span>
                    </template>
                  </multiselect>
                  <!-- <select :name="addressType+' Country'" v-model="address.cntryId" id="country" class="selectpicker"  data-live-search="false">
                                                            <template v-for="(country ,ind) in countryList"  >
                                                                <option :key="ind" :value="country._id" >{{country.name}}</option>
                                                            </template>

                                                        </select> -->
                  <span class="v-messages error-msg" v-if="errors[0]">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <!-- <ul class="form_list">

                                <template v-for="(llc ,index) in llc_type">
                                    <li  :key="index"  >

                                        <input class="styled-checkbox"  @click="checkLlc($event,llc)" :id="'llc_'+index"  v-model="llc.selected" type="checkbox" value="value1">
                                        <label :for="'llc_'+index">{{llc.name}}</label>
                                    </li>
                                </template>

                                </ul> -->
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import Multiselect from 'vue-multiselect-inv';
import VuePhoneNumberInput from 'vue-phone-number-input';
import Datepicker from 'vuejs-datepicker';
import BaseRadioButtonGroup from '@/components/BaseRadioButtonGroup.vue';
import uploadFile from '@/components/uploadFile.vue';
import _ from 'lodash';

export default {
  data() {
    return {
      openDate: new Date().setFullYear(new Date().getFullYear()),
    };
  },
  components: {
    ValidationProvider,
    Multiselect,
    VuePhoneNumberInput,
    Datepicker,
    BaseRadioButtonGroup,
    uploadFile,
  },
  props: {
    Payload: {
      type: Object,
      required: true,
    },
    autoSave: {
      type: Function,
      required: true,
    },
    bus_structure: {
      type: Array,
      required: false,
    },
    usstateList: {
      type: Array,
      required: true,
      default: () => [],
    },
    updatellcTypes: {
      type: Function,
      required: true,
    },
    llc_type: {
      type: Array,
      required: true,
    },
  },
  computed: {
    articlesInput: {
      get() {
        const articles = this.Payload.articlesOfOrgAndDriverLiecenses;
        return Array.isArray(articles) && articles.length === 0
          ? ''
          : articles || '';
      },
      set(value) {
        if (!this.Payload) {
          this.Payload = {};
        }
        this.Payload.articlesOfOrgAndDriverLiecenses = value;
      },
    },
    checkCaliforniaState() {
      if (
        this.Payload.incorporatedStateDetails != null &&
        _.has(this.Payload.incorporatedStateDetails, '_id')
      ) {
        if (this.Payload.incorporatedStateDetails) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    addsecretaryStateNumber() {
      this.Payload.secretaryStateNumbers.push({ number: '' });
    },
    removesecretaryStateNumber(index) {
      this.Payload.secretaryStateNumbers.splice(index, 1);
    },
    removeDoc(index, model, type = '') {
      model.splice(index, 1);
      if (type == 'purchaseAgreementDocs') {
        if (this.Payload.purchaseAgreementDocs.length == 0) {
          this.purchaseAgreementDocs = '';
        } else {
          this.purchaseAgreementDocs = 'purchaseAgreementDocs';
        }
      }
    },
    onUploadComplete(data) {
      if (
        _.has(data, 'finalDocuments') &&
        data['finalDocuments'].length > 0 &&
        _.has(data, 'indexValue') &&
        _.has(data, 'payLoadKey') &&
        _.has(data, 'payLoadDocumentsKey')
      ) {
        if (_.has(data, 'allowMultiple') && data['allowMultiple'] == true) {
          _.forEach(data['finalDocuments'], (item) => {
            if (data['takeIndex']) {
              //alert(JSON.stringify( this.Payload[data['payLoadKey']][data['indexValue']][data['payLoadDocumentsKey']]))
              this.Payload[data['payLoadKey']][data['indexValue']][
                data['payLoadDocumentsKey']
              ].push(item);
            } else {
              if (data['payLoadDocumentsKey'] != '') {
                this.Payload[data['payLoadKey']][
                  data['payLoadDocumentsKey']
                ].push(item);
              } else {
                if (data['payLoadKey'] == 'purchaseAgreementDocs') {
                  this.purchaseAgreementDocs = 'purchaseAgreementDocs';
                }
                this.Payload[data['payLoadKey']].push(item);
              }
            }
          });
        } else {
          if (data['payLoadKey'] == 'logo') {
            // alert(data['finalDocuments'][0]['url']);
            this.Payload['logo'] = data['finalDocuments'][0]['url'];
          } else if (data['takeIndex'] && data['payLoadDocumentsKey'] != '') {
            this.Payload[data['payLoadKey']][data['indexValue']][
              data['payLoadDocumentsKey']
            ] = data['finalDocuments'][0];
          } else if (!data['takeIndex'] && data['payLoadDocumentsKey'] == '') {
            this.Payload[data['payLoadKey']] = data['finalDocuments'][0];
          }
        }
      }
    },
    formatEinInput() {
      // Get the raw input value
      let inputValue = this.Payload.EIN;

      // Remove any non-numeric characters except "-"
      inputValue = inputValue.replace(/[^0-9]/g, '');

      // Format the input as EIN (XX-XXXXXXX)
      if (inputValue.length > 2) {
        this.Payload.EIN = `${inputValue.slice(0, 2)}-${inputValue.slice(2, 10)}`;
      } else {
        this.Payload.EIN = inputValue; // Keep the first two characters as-is
      }
    },
    updatePhoneNumber(item) {
      console.log(JSON.stringify(item));

      if (item.isValid) {
        this.Payload.busContact.phoneCode = item.countryCallingCode;
        this.Payload.busContact.phone = item.nationalNumber;
      }
    },
    updatePhoneNumber1(item) {
      if (item.isValid) {
        this.Payload.busphoneCode = item.countryCallingCode;
        this.Payload.busPhone = item.nationalNumber;
      }
    },
    updateIncorporatedState(item) {
      this.Payload.articlesOfOrgAndDriverLiecenses = [];
      if (item && _.has(item, '_id')) {
        this.Payload.incorporatedState = item._id;
      } else {
        this.Payload.incorporatedStateDetails = null;
        this.Payload.incorporatedState = '';
      }
    },
    updateBusStructure(item) {
      if (item && _.has(item, '_id')) {
        this.Payload.busStructureId = item._id;
      } else {
        this.Payload.busStructureDetails = null;
        this.Payload.busStructureDetailsOther = null;
        this.Payload.busStructureId = '';
      }
    },
  },
};
</script>
