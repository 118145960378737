<template>
  <ValidationProvider :name="'supplier'" v-slot="{ errors }">
    <form>
      <div>
        <h4 class="title">
          Supplier Info
          <!-- <span>
                        <img
                          :id="'supplierInfo'"
                          style="width: 14px; margin-top: -4px"
                          src="@/assets/images/info_icon.svg"
                        />
                        <b-tooltip :target="'supplierInfo'">
                          <p>
                            Required for California registration
                            https://www.cdtfa.ca.gov/formspubs/pub107/applying-for-a-sellers-permit.htm
                          </p>
                        </b-tooltip>
                      </span> -->
        </h4>
        <label class="form_subtitle">
          If you are already registered in the state of California, you can skip
          this question.
        </label>
        <div class="supplier-details">
          <template v-for="(supplier, index) in Payload.supplier">
            <div :key="index" class="suppliers_list">
              <div class="suppliers_cnt">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Type</label>
                      <ValidationProvider
                        name="Supplier Type"
                        v-slot="{ errors }"
                      >
                        <multiselect
                          v-model="supplier.typeDetails"
                          :value="'_id'"
                          :options="supplier_type"
                          :searchable="true"
                          :close-on-select="true"
                          :allow-empty="true"
                          :multiple="false"
                          @input="updatesupplier(supplier)"
                          label="name"
                          placeholder="Select Supplier Type"
                          track-by="name"
                          :hide-selected="false"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                          >
                            <span
                              class="multiselect__selectcustom"
                              v-if="values.length && !isOpen"
                              >{{ values.length }} Supplier Type (s)
                              selected</span
                            >
                            <span
                              class="multiselect__selectcustom"
                              v-if="values.length && isOpen"
                            ></span>
                          </template>
                        </multiselect>

                        <span class="v-messages error-msg" v-if="errors[0]">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div
                  class="delete_btn"
                  v-if="Payload.supplier.length > 1"
                  @click="removeSuplier(index)"
                >
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label"
                        >Name a supplier/vendor in the US
                      </label>
                      <ValidationProvider
                        :name="'Supplier Name' + index"
                        rules="max:50"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          @blur="autoSave"
                          class="form-control"
                          name="MarketPlace"
                          v-model="supplier.name"
                        />
                        <span class="v-messages error-msg" v-if="errors[0]"
                          >{{
                            supplier.name.length > 50
                              ? 'Supplier Name allow only 50 characters'
                              : 'Supplier Name is required'
                          }}
                        </span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Phone Number</label>
                      <ValidationProvider
                        name="Supplier Phone Number"
                        rules="phonenumber"
                        v-slot="{ errors }"
                      >
                        <VuePhoneNumberInput
                          default-country-code="US"
                          :no-example="true"
                          :only-countries="['US']"
                          :no-country-selector="true"
                          @update="updatePhoneNumber"
                          :required="true"
                          v-model="supplier.phone"
                        />
                        <span class="v-messages error-msg" v-if="errors[0]">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Email Address</label>
                      <ValidationProvider
                        :name="'Supplier Email'"
                        rules="email"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          @blur="autoSave"
                          class="form-control"
                          name="MarketPlace"
                          v-model="supplier.email"
                        />
                        <span class="v-messages error-msg" v-if="errors[0]"
                          >{{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <addressComponent
                  v-bind:autoSave="autoSave"
                  v-if="ClientDataloaded && supplier.typeDetails"
                  v-bind:address="supplier.address"
                  v-bind:addressType="'supplier'"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div @click="addSuplier()" class="primary_btn add_more">Add Supplier</div>
    </form>
    <span class="v-messages error-msg" v-if="errors[0]">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import Multiselect from 'vue-multiselect-inv';
import VuePhoneNumberInput from 'vue-phone-number-input';
import addressComponent from '@/components/address.vue';
import _ from 'lodash';

export default {
  data() {
    return {
      openDate: new Date().setFullYear(new Date().getFullYear()),
    };
  },
  components: {
    ValidationProvider,
    Multiselect,
    VuePhoneNumberInput,
    addressComponent,
  },
  props: {
    Payload: {
      type: Object,
      required: true,
    },
    autoSave: {
      type: Function,
      required: true,
    },
    ClientDataloaded: {
      type: Boolean,
      required: true,
    },
    supplier_type: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    updatesupplier(item) {
      item.typeId = null;
      if (_.has(item, 'typeDetails') && _.has(item['typeDetails'], '_id')) {
        item.typeId = item.typeDetails['_id'];
      }
    },
    removeSuplier(index) {
      // alert(index);
      this.Payload.supplier.splice(index, 1);
    },
    updatePhoneNumber(item) {
      console.log(JSON.stringify(item));

      if (item.isValid) {
        this.Payload.busContact.phoneCode = item.countryCallingCode;
        this.Payload.busContact.phone = item.nationalNumber;
      }
    },
    addSuplier() {
      let sp = {
        typeId: null,
        typeDetails: '',
        name: '',
        email: '',
        address: {
          addr1: '',
          addr2: '',
          stateId: null,
          cityId: null,
          zip: '',
          cntryId: null,
        },
        phone: '',
        phoneCode: '',
      };
      this.Payload.supplier.push(sp);
    },
  },
};
</script>
