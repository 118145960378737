<template>
  <aside class="steps_menu">
    <div class="logo">
      <router-link to="/dashboard">
        <img class="logonew" src="@/assets/images/logo2.png" />
        <!-- <img src="@/assets/images/logo.svg">
                    <img class="logo_text" src="@/assets/images/logo_text.svg"> -->
      </router-link>
    </div>
    <!--
            blocks:{"form1":{"active":true ,
            "validator":"legal_informationForm" ,
            "label":'Legal Information' ,
             "formIsValid":false} ,
             clientData.statusId <=1
             -->

    <div class="registration_menu">
      <ul id="progressbar">
        <template v-if="checkClientStatus()">
          <li
            @click="changeBlock(1)"
            :class="{
              active: show_block >= 1,
              completed: blocks.form1.formIsValid,
            }"
          >
            <p><span class="legal"></span>General Information</p>
          </li>
          <li
            @click="changeBlock(2)"
            :class="{
              active: show_block >= 2,
              completed: blocks.form1.formIsValid && blocks.form2.formIsValid,
            }"
          >
            <p><span class="business"></span>Business Information</p>
          </li>
          <li
            @click="changeBlock(3)"
            :class="{
              active: show_block >= 3,
              completed:
                blocks.form1.formIsValid &&
                blocks.form2.formIsValid &&
                blocks.form3.formIsValid,
            }"
          >
            <p><span class="owner"></span>Responsible Parties</p>
          </li>
          <li
            @click="changeBlock(4)"
            :class="{
              active: show_block >= 4,
              completed:
                blocks.form1.formIsValid &&
                blocks.form2.formIsValid &&
                blocks.form3.formIsValid &&
                blocks.form4.formIsValid,
            }"
          >
            <p><span class="states"></span>Registered States</p>
          </li>
        </template>
        <template v-else>
          <li
            :class="{
              active: show_block >= 1,
              completed: blocks.form1.formIsValid,
            }"
          >
            <p><span class="legal"></span>General Information</p>
          </li>
          <li
            :class="{
              active: show_block >= 2,
              completed: blocks.form1.formIsValid && blocks.form2.formIsValid,
            }"
          >
            <p><span class="business"></span>Business Information</p>
          </li>
          <li
            :class="{
              active: show_block >= 3,
              completed:
                blocks.form1.formIsValid &&
                blocks.form2.formIsValid &&
                blocks.form3.formIsValid,
            }"
          >
            <p><span class="owner"></span>Responsible Parties</p>
          </li>
          <li
            :class="{
              active: show_block >= 4,
              completed:
                blocks.form1.formIsValid &&
                blocks.form2.formIsValid &&
                blocks.form3.formIsValid &&
                blocks.form4.formIsValid,
            }"
          >
            <p><span class="states"></span>Registered States</p>
          </li>
        </template>
      </ul>
      <figure>
        <img src="@/assets/images/registration_img.svg" alt="registration" />
      </figure>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    show_block: Number,
    blocks: Object,
    checkClientStatus: Function,
    toggleBlock: Function,
  },
  methods: {
    changeBlock(blockNumber) {
      this.$emit('update:show_block', blockNumber); // Emit an event to update the parent state
      this.toggleBlock(true);
    },
  },
};
</script>
