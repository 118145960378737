<template>
  <ValidationProvider :name="'bankDetails'" v-slot="{ errors }">
    <form>
      <div>
        <h4 class="title">Bank Details</h4>

        <label class="form_subtitle"
          >Please state the bank account information below for paying sales tax
          owed and permits to states</label
        >
        <div class="row">
          <div class="col">
            <div class="form-group">
              <ValidationProvider
                name="Bank"
                rules="required"
                v-slot="{ errors }"
              >
                <multiselect
                  v-model="Payload.bankDetails"
                  :value="'_id'"
                  :options="bank"
                  :searchable="true"
                  :close-on-select="false"
                  :allow-empty="true"
                  :multiple="true"
                  label="name"
                  placeholder="Select Banks"
                  track-by="name"
                  @input="addBank"
                  :hide-selected="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      class="multiselect__selectcustom"
                      v-if="values.length && !isOpen"
                      >{{ values.length }} Bank (s) selected</span
                    >
                    <span
                      class="multiselect__selectcustom"
                      v-if="values.length && isOpen"
                    ></span>
                  </template>
                </multiselect>

                Selected:
                <span>{{
                  Payload.bankDetails.map((option) => option.name).join(', ')
                }}</span>

                <span class="v-messages error-msg" v-if="errors[0]">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div>
          <div
            v-for="(bnk, indx) in Payload.banks"
            :key="indx"
            class="bank_details_list"
          >
            <div
              v-if="Payload.banks.length > 1"
              class="delete_btn"
              @click="removeBank(indx)"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Bank Name </label>
                  <ValidationProvider
                    :name="'Bank Name' + indx"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      v-show="
                        Payload.bankDetails[indx] &&
                        Payload.bankDetails[indx].name !== 'Others'
                      "
                      type="text"
                      @blur="autoSave"
                      :disabled="
                        Payload.bankDetails[indx] &&
                        Payload.bankDetails[indx].name !== 'Others'
                      "
                      v-model="bnk.name"
                      class="form-control"
                    />
                    <input
                      v-if="
                        Payload.bankDetails[indx] &&
                        Payload.bankDetails[indx].name === 'Others'
                      "
                      type="text"
                      v-model="bnk.otherName"
                      class="form-control"
                    />
                    <span class="v-messages error-msg" v-if="errors[0]"
                      >{{ errors[0] ? 'Bank Name is required' : '' }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Account Type </label>
                  <ValidationProvider
                    name="Acccounts"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      v-model="bnk.accType"
                      :options="accTypes"
                      :searchable="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      :multiple="false"
                      placeholder="Select Account Type"
                      :hide-selected="false"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                      >
                        <span
                          class="multiselect__selectcustom"
                          v-if="values.length && !isOpen"
                          >{{ values.length }} Type (s) selected</span
                        >
                        <span
                          class="multiselect__selectcustom"
                          v-if="values.length && isOpen"
                        ></span>
                      </template>
                    </multiselect>

                    <span class="v-messages error-msg" v-if="errors[0]">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col">
                <div class="form-group date_desable">
                  <label class="form_label">Bank Phone Number</label>
                  <ValidationProvider
                    name="Bank Phone Number"
                    rules="required|phonenumber"
                    v-slot="{ errors }"
                  >
                    <VuePhoneNumberInput
                      default-country-code="US"
                      :no-example="true"
                      :only-countries="['US']"
                      :no-country-selector="true"
                      @update="updatePhoneNumber"
                      :required="true"
                      v-model="bnk.phone"
                    />
                    <span class="v-messages error-msg" v-if="errors[0]">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Account Name</label>
                  <ValidationProvider
                    :name="'Account Name'"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      @blur="autoSave"
                      v-model="bnk.accountName"
                      class="form-control"
                    />
                    <span class="v-messages error-msg" v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="form_label">Account Number</label>
                  <ValidationProvider
                    :name="'Account number'"
                    rules="required|Alphanumeric|max:15"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      @blur="autoSave"
                      v-model="bnk.accNo"
                      class="form-control"
                    />
                    <span class="v-messages error-msg" v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="form_label">Routing Number</label>
                  <ValidationProvider
                    :name="'Routing Number'"
                    rules="required|onlyNumbers|min:9|max:9"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      @blur="autoSave"
                      v-model="bnk.routingNo"
                      class="form-control"
                    />
                    <span class="v-messages error-msg" v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <addressComponent
              v-bind:autoSave="autoSave"
              v-bind:address="bnk.address"
              v-bind:addressType="'bank' + indx"
            />
            <!-- <statecity v-bind:address="bnk" v-bind:addressType="'bank'"  v-bind:requiredCntry="false" />                                             -->
          </div>
        </div>
      </div>
    </form>
    <span class="v-messages error-msg" v-if="errors[0]">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import VuePhoneNumberInput from 'vue-phone-number-input';
import addressComponent from '@/components/address.vue';
import Multiselect from 'vue-multiselect-inv';
import _ from 'lodash';

export default {
  data() {
    return {
      openDate: new Date().setFullYear(new Date().getFullYear()),
    };
  },
  components: {
    ValidationProvider,
    VuePhoneNumberInput,
    Multiselect,
    addressComponent,
  },
  props: {
    Payload: {
      type: Object,
      required: true,
    },
    autoSave: {
      type: Function,
      required: true,
    },
    bank: {
      type: Array,
      required: true,
      default: () => [],
    },
    accTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    addBank(item) {
      this.log(item);
      let tempBanks = _.cloneDeep(this.Payload.banks);
      if (this.Payload.bankDetails.length > 0) {
        this.Payload.banks = [];
        _.forEach(this.Payload.bankDetails, (bank) => {
          let temp_bank = {
            id: bank._id, /// master
            otherName: bank.otherName,
            accType: '',
            stateId: bank.stateId,
            stateDetails: bank.stateDetails,
            cityId: bank.cityId,
            cityDetails: bank.cityDetails,
            stateList: bank.stateList,
            routingNo: bank.routingNo,
            accNo: bank.accNo,
            accountName: '',
            name: bank.name,
            phone: null,
            phoneCode: '+1',
            address: {
              addr1: '',
              addr2: '',
              stateId: null,
              cityId: null,
              zip: '',
              cntryId: null,
            },
          };
          if (_.has(bank, 'accountName')) {
            temp_bank['accountName'] = bank['accountName'];
          }
          let tmp = _.find(this.Payload.banks, { id: bank._id });
          if (!tmp) {
            if (this.clientData != null && _.has(this.clientData, 'banks')) {
              let dbBank = _.find(this.clientData['banks'], { id: bank._id });
              //alert(JSON.stringify(bank._id));
              if (dbBank) {
                //this.loa
                dbBank['name'] = bank.name;
                if (!_.has(dbBank, 'accountName')) {
                  dbBank = Object.assign(dbBank, { accountName: '' });
                }
                this.Payload.banks.push(dbBank);
              } else {
                let selectedBnk = _.find(tempBanks, { name: bank.name });
                if (selectedBnk) {
                  temp_bank = {
                    id: selectedBnk._id, /// master
                    otherName: selectedBnk.otherName,
                    stateId: selectedBnk.stateId,
                    stateDetails: selectedBnk.stateDetails,
                    cityId: selectedBnk.cityId,
                    cityDetails: selectedBnk.cityDetails,
                    stateList: selectedBnk.stateList,
                    routingNo: selectedBnk.routingNo,
                    accNo: selectedBnk.accNo,
                    name: selectedBnk.name,
                    accountName: '',
                  };

                  if (_.has(selectedBnk, 'accountName')) {
                    temp_bank['accountName'] = selectedBnk['accountName'];
                  }
                }

                this.Payload.banks.push(temp_bank);
              }
            } else {
              this.Payload.banks.push(temp_bank);
            }
          }
        });
      } else {
        this.Payload.banks = [];
      }
    },
    removeBank(indx) {
      this.Payload.banks.splice(indx, 1);
      this.Payload.bankDetails = this.Payload.banks;
    },
    updatePhoneNumber(item) {
      console.log(JSON.stringify(item));

      if (item.isValid) {
        this.Payload.busContact.phoneCode = item.countryCallingCode;
        this.Payload.busContact.phone = item.nationalNumber;
      }
    },
  },
};
</script>
