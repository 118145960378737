var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"steps_menu"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('img',{staticClass:"logonew",attrs:{"src":require("@/assets/images/logo2.png")}})])],1),_c('div',{staticClass:"registration_menu"},[_c('ul',{attrs:{"id":"progressbar"}},[(_vm.checkClientStatus())?[_c('li',{class:{
            active: _vm.show_block >= 1,
            completed: _vm.blocks.form1.formIsValid,
          },on:{"click":function($event){return _vm.changeBlock(1)}}},[_vm._m(0)]),_c('li',{class:{
            active: _vm.show_block >= 2,
            completed: _vm.blocks.form1.formIsValid && _vm.blocks.form2.formIsValid,
          },on:{"click":function($event){return _vm.changeBlock(2)}}},[_vm._m(1)]),_c('li',{class:{
            active: _vm.show_block >= 3,
            completed:
              _vm.blocks.form1.formIsValid &&
              _vm.blocks.form2.formIsValid &&
              _vm.blocks.form3.formIsValid,
          },on:{"click":function($event){return _vm.changeBlock(3)}}},[_vm._m(2)]),_c('li',{class:{
            active: _vm.show_block >= 4,
            completed:
              _vm.blocks.form1.formIsValid &&
              _vm.blocks.form2.formIsValid &&
              _vm.blocks.form3.formIsValid &&
              _vm.blocks.form4.formIsValid,
          },on:{"click":function($event){return _vm.changeBlock(4)}}},[_vm._m(3)])]:[_c('li',{class:{
            active: _vm.show_block >= 1,
            completed: _vm.blocks.form1.formIsValid,
          }},[_vm._m(4)]),_c('li',{class:{
            active: _vm.show_block >= 2,
            completed: _vm.blocks.form1.formIsValid && _vm.blocks.form2.formIsValid,
          }},[_vm._m(5)]),_c('li',{class:{
            active: _vm.show_block >= 3,
            completed:
              _vm.blocks.form1.formIsValid &&
              _vm.blocks.form2.formIsValid &&
              _vm.blocks.form3.formIsValid,
          }},[_vm._m(6)]),_c('li',{class:{
            active: _vm.show_block >= 4,
            completed:
              _vm.blocks.form1.formIsValid &&
              _vm.blocks.form2.formIsValid &&
              _vm.blocks.form3.formIsValid &&
              _vm.blocks.form4.formIsValid,
          }},[_vm._m(7)])]],2),_vm._m(8)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"legal"}),_vm._v("General Information")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"business"}),_vm._v("Business Information")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"owner"}),_vm._v("Responsible Parties")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"states"}),_vm._v("Registered States")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"legal"}),_vm._v("General Information")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"business"}),_vm._v("Business Information")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"owner"}),_vm._v("Responsible Parties")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"states"}),_vm._v("Registered States")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',[_c('img',{attrs:{"src":require("@/assets/images/registration_img.svg"),"alt":"registration"}})])}]

export { render, staticRenderFns }