var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('ValidationProvider',{attrs:{"name":"inventoryLocations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('h4',{staticClass:"title"},[_vm._v("Inventory Location - Type of Location")]),_c('label',{staticClass:"form_subtitle"},[_vm._v(" Please provide addresses for all locations where inventory is stored in the US. (i.e. warehouse, manufacturing plant, third-party fulfillment center, retail stores) ")]),_c('div',{staticClass:"inventory-details"},[_vm._l((_vm.Payload.inventoryLocations),function(inventory,index){return [_c('div',{key:inventory._id || index,staticClass:"inventory_list"},[_c('div',{staticClass:"inventory_cnt"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"name":"Market Place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseRadioButtonGroup',{attrs:{"options":_vm.inventory_location_type,"selectedValue":inventory.typeId || '',"name":'inventory_location_type_' + index},on:{"input":function($event){return _vm.updateinventory(inventory)}},model:{value:(inventory.typeDetails),callback:function ($$v) {_vm.$set(inventory, "typeDetails", $$v)},expression:"inventory.typeDetails"}}),(
                          inventory.typeDetails &&
                          inventory.typeDetails._id == 7
                        )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(inventory.otherInventoryLocation),expression:"inventory.otherInventoryLocation"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter other Inventory Location"},domProps:{"value":(inventory.otherInventoryLocation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(inventory, "otherInventoryLocation", $event.target.value)}}}):_vm._e(),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])]),(_vm.Payload.inventoryLocations.length > 1)?_c('div',{staticClass:"delete_btn",on:{"click":function($event){return _vm.removeInventory(index)}}},[_c('b-icon',{attrs:{"icon":"trash","aria-hidden":"true"}})],1):_vm._e(),(_vm.ClientDataloaded)?_c('addressComponent',{attrs:{"autoSave":_vm.autoSave,"address":inventory.address,"addressType":'inventory' + index,"isRequired":inventory.typeDetails &&
                  inventory.typeDetails.name !== 'N/A'}}):_vm._e()],1)])]})],2)]),_c('div',{staticClass:"primary_btn add_more",on:{"click":function($event){return _vm.addInventory()}}},[_vm._v(" Add Inventory ")]),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }