var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('div',[_c('h4',{staticClass:"title"},[_vm._v("Accounting Method")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('p',{staticStyle:{"margin-bottom":"15px","color":"#19213e"}},[_vm._v(" For most companies, there accounting period year end date is 12/31. If unsure, please refer to your previous year's tax return. ")]),_c('ValidationProvider',{attrs:{"name":"TaxPeriodbeginning","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"value":'id',"options":_vm.allMonths,"searchable":true,"close-on-select":true,"allow-empty":false,"multiple":false,"label":"name","placeholder":"Select Tax period beginning","track-by":"name"},on:{"input":_vm.updatetaxBegginingPeriodDetails},model:{value:(_vm.Payload.taxBegginingPeriodDetails),callback:function ($$v) {_vm.$set(_vm.Payload, "taxBegginingPeriodDetails", $$v)},expression:"Payload.taxBegginingPeriodDetails"}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Description of Items Sold")]),_c('ValidationProvider',{attrs:{"name":"Description of Items Sold","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.itemDescription),expression:"Payload.itemDescription"}],staticClass:"form-control",attrs:{"type":"text","name":"itemDescription"},domProps:{"value":(_vm.Payload.itemDescription)},on:{"blur":_vm.autoSave,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "itemDescription", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('ValidationProvider',{attrs:{"name":"NAICS Code","rules":"required|min:5|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.NAICSCode),expression:"Payload.NAICSCode"}],staticClass:"form-control",attrs:{"type":"number","name":"NAICSCode"},domProps:{"value":(_vm.Payload.NAICSCode)},on:{"blur":_vm.autoSave,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "NAICSCode", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Accounting Method")]),_c('ValidationProvider',{attrs:{"name":'Accounting Method',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.acc_method,"searchable":true,"multiple":false,"close-on-select":true,"allow-empty":false,"label":"name","value":"value1","placeholder":"Select Accounting Method","track-by":"name","name":'Accounting Method'},on:{"input":_vm.selectedAccount},model:{value:(_vm.Payload.accMethodDetails),callback:function ($$v) {_vm.$set(_vm.Payload, "accMethodDetails", $$v)},expression:"Payload.accMethodDetails"}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0] ? 'Accounting Method is required' : ''))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Input Your Industry")]),_c('ValidationProvider',{attrs:{"name":"Industry","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.industryIds),expression:"Payload.industryIds"}],staticClass:"form-control",attrs:{"type":"text","name":"industryIds","placeholder":"i.e. Fitness Equipment, Food, Beverage, Appareal, Pet Supplies, etc."},domProps:{"value":(_vm.Payload.industryIds)},on:{"blur":_vm.autoSave,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "industryIds", $event.target.value)}}}),(errors[0])?_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form_label"},[_c('b',[_vm._v("Accounting Period Year End Date")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form_label"},[_vm._v("NAICS Code "),_c('a',{attrs:{"href":"https://www.naics.com/search/","target":"_blank"}},[_c('img',{staticStyle:{"width":"14px","margin-top":"-4px"},attrs:{"src":require("@/assets/images/question_mark.svg")}})])])}]

export { render, staticRenderFns }